import { rem } from 'polished';
import * as React from 'react';
import styled, { css } from 'styled-components';
import { itemIsSkvelko } from '../../../utilities';
import { __ } from 'react-i18n';

const Sticker = css`
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
  width: ${rem(115)};
  height: ${rem(115)};
  position: absolute;
  top: ${rem(10)};
  left: ${rem(10)};
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotSkvelkoTextWrapper = styled.div`
  text-transform: uppercase;
  font-size: ${rem(24)};
  font-weight: 800;
  line-height: ${rem(25)};
`;

const Wrapper = styled.div`
  ${Sticker};
  background: ${({ theme }) => theme.color.primary};
`;

const SkvelkoTextWrapper = styled.div``;

const SvelkoTopTextWrapper = styled.div`
  font-size: 15px;
  line-height: 15px;
  font-weight: bold;
`;

const SkvelkoBottomText = styled.div`
  text-transform: uppercase;
  font-weight: 800;
  font-size: ${rem(20)};
  line-height: ${rem(18)};
  margin-top: ${rem(10)};
`;

interface SaleStickerProductProps {
  product: any;
}

const SaleStickerProduct = (props: SaleStickerProductProps) => {
  const { product } = props;

  const isSkvelko = itemIsSkvelko(product);
  return (
    <Wrapper>
      {isSkvelko ? (
        <SkvelkoTextWrapper>
          {' '}
          <SvelkoTopTextWrapper>
            {' '}
            <div>{__('len')}</div> <div>{__('v eshope')}:</div>{' '}
          </SvelkoTopTextWrapper>{' '}
          <SkvelkoBottomText> {__('DOBRÁ CENA')} </SkvelkoBottomText>{' '}
        </SkvelkoTextWrapper>
      ) : (
        <NotSkvelkoTextWrapper> {__('AKCIA')} </NotSkvelkoTextWrapper>
      )}
    </Wrapper>
  );
};

export default SaleStickerProduct;
