import * as React from 'react';
import styled from 'styled-components';
import { ButtonStyledStyles, ButtonStyles } from '../_helpers/form/Button';
import { __ } from 'react-i18n';
import { rem } from 'polished';
import ConfirmModal from '../_helpers/Modal/ConfirmModal';
import { Field, Form } from 'react-final-form';
import { required } from '../../utilities/validators';
import { ErrorPopup } from '../_helpers/form/FormStyles';
import { InputStyled } from '../_helpers/form/Input';
import { useState } from 'react';
import * as cookie from 'react-cookies';
import API from '../../services/API';
import Alert from '../Alert/Alert';
import { Container } from '../../theme/libraries/grid';
import { CheckboxStyled } from '../_helpers/form/Checkbox';

interface UpsellNewsletterProps {
  newsletterCouponActive: boolean;
  newsletterCouponAppearSeconds: number;
  user: any;
}

const UpsellNewsletter = (props: UpsellNewsletterProps) => {
  const CLOSE_COOKIE_NAME = '6CvUN04VDMgu9ToRKlOPTocyWgZsDmDO';
  const APPLIED_COOKIE_NAME = '4XED2LvrHhcRPCbb9YlzfTaDj2PPWMRt';

  const { newsletterCouponActive, newsletterCouponAppearSeconds, user } = props;

  const [widgetVisible, setWidgetVisible] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [thankYouModalVisible, setThankYouModalVisible] = useState(false);
  const [
    formModalExistsUserErrorVisible,
    setFormModalExistsUserErrorVisible,
  ] = useState(false);

  const [
    formModalExistsShoolCompanyUserErrorVisible,
    setFormModalExistsSchoolCompanyUserErrorVisible,
  ] = useState(false);

  const [
    newsletterCouponFormBodyText,
    setNewsletterCouponFormBodyText,
  ] = useState<string | undefined>('');
  const [
    newsletterCouponFormHeaderText,
    setNewsletterCouponFormHeaderText,
  ] = useState<string | undefined>('');
  const [
    newsletterCouponThankYouBodyText,
    setNewsletterCouponThankYouBodyText,
  ] = useState<string | undefined>('');
  const [
    newsletterCouponThankYouHeaderText,
    setNewsletterCouponThankYouHeaderText,
  ] = useState<string | undefined>('');

  React.useEffect(() => {
    const cookiesReject = cookie.load('COOKIES_REJECT');
    if (newsletterCouponActive && !user && cookiesReject === '0') {
      setTimeout(async () => {
        const settings = await API.getSettingByName(
          'newsletterCouponFormBodyText,newsletterCouponFormHeaderText,newsletterCouponShowAfterRejectionDays,newsletterCouponThankYouBodyText,newsletterCouponThankYouHeaderText',
        );
        if (settings) {
          setNewsletterCouponFormBodyText(
            settings.find((s) => s.name === 'newsletterCouponFormBodyText')
              ?.value,
          );
          setNewsletterCouponFormHeaderText(
            settings.find((s) => s.name === 'newsletterCouponFormHeaderText')
              ?.value,
          );
          setNewsletterCouponThankYouBodyText(
            settings.find((s) => s.name === 'newsletterCouponThankYouBodyText')
              ?.value,
          );
          setNewsletterCouponThankYouHeaderText(
            settings.find(
              (s) => s.name === 'newsletterCouponThankYouHeaderText',
            )?.value,
          );

          const newsletterCouponShowAfterRejectionDays = settings.find(
            (s) => s.name === 'newsletterCouponShowAfterRejectionDays',
          )?.value;

          let canShow = false;
          const closeCookieValue = cookie.load(CLOSE_COOKIE_NAME);
          const appliedCookieValue = cookie.load(APPLIED_COOKIE_NAME);

          if (!appliedCookieValue) {
            if (closeCookieValue && newsletterCouponShowAfterRejectionDays) {
              const currentDate = new Date();
              const closeCookieDate = new Date(closeCookieValue);
              closeCookieDate.setDate(
                closeCookieDate.getDate() +
                  parseInt(newsletterCouponShowAfterRejectionDays),
              );

              if (currentDate > closeCookieDate) {
                canShow = true;
              }
            } else {
              canShow = true;
            }
          }

          if (canShow) {
            setWidgetVisible(true);
          }
        }
      }, newsletterCouponAppearSeconds * 1000);
    }
  }, [newsletterCouponActive, newsletterCouponAppearSeconds]);

  const formRef: any = React.useRef(null);

  const onWidgetButtonClick = () => {
    setFormModalVisible(true);
  };

  const onWidgetCloseClick = () => {
    const currentDateTime = new Date().toISOString();
    cookie.save(CLOSE_COOKIE_NAME, currentDateTime, {
      path: '/',
    });

    setWidgetVisible(false);
  };

  const onFormModalClose = () => {
    setFormModalVisible(false);
  };

  const onThankYouModalClose = () => {
    setThankYouModalVisible(false);
  };

  const onSendFormClick = async () => {
    if (formRef.current) {
      const state = formRef.current.getState();
      if (!state.hasValidationErrors) {
        const customerCardVerifyResponse: any = await API.customerCardVerify(
          {},
          { ean: false, email: true, value: state.values.email },
        );

        const isSchoolCompanyEmailExistsResponse = await API.isEmailExist({
          email: state.values.email,
          isSchoolOrCompany: true,
        });

        if (customerCardVerifyResponse?.user) {
          setFormModalExistsUserErrorVisible(true);
          setFormModalExistsSchoolCompanyUserErrorVisible(false);
        } else if (isSchoolCompanyEmailExistsResponse) {
          setFormModalExistsUserErrorVisible(false);
          setFormModalExistsSchoolCompanyUserErrorVisible(true);
        } else {
          API.upsellNewsletterApply(
            {},
            { name: state.values.name, email: state.values.email },
          )
            .then(() => {
              setFormModalVisible(false);
              setThankYouModalVisible(true);
              setWidgetVisible(false);
              cookie.save(APPLIED_COOKIE_NAME, 1, {
                path: '/',
              });
            })
            .catch(() => {});
        }
      } else {
        formRef.current.submit();
      }
    }
  };

  return (
    <>
      {widgetVisible && !formModalVisible && !thankYouModalVisible ? (
        <WidgetWrapper className="base">
          <WidgetBox>
            <ContentWrapper>
              <WidgetText> {__('Zľava 20% na prvý nákup')} </WidgetText>
              <Button
                onClick={() => {
                  onWidgetButtonClick();
                }}
                className="primary"
              >
                {' '}
                {__('Mám záujem')}{' '}
              </Button>
            </ContentWrapper>
            <CancelButton
              src={'/images/cancel.svg'}
              onClick={() => {
                onWidgetCloseClick();
              }}
            />
            <StarsImage src="/images/stars01.png" />
          </WidgetBox>
        </WidgetWrapper>
      ) : (
        <></>
      )}

      {formModalVisible ? (
        <ConfirmModal
          title={newsletterCouponFormHeaderText}
          titleFontWeight="bold"
          bodyText={
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: newsletterCouponFormBodyText
                    ? newsletterCouponFormBodyText
                    : '',
                }}
              />

              {formModalExistsUserErrorVisible ? (
                <ErrorMessage type="error">
                  {' '}
                  {__(
                    'Zákazník s týmto emailom už u nás získal zľavu 20% na prvý nákup',
                  )}{' '}
                </ErrorMessage>
              ) : (
                <></>
              )}

              {formModalExistsShoolCompanyUserErrorVisible ? (
                <ErrorMessage type="error">
                  {' '}
                  {__('Účet s takýmto emailom už u nás existuje')}{' '}
                </ErrorMessage>
              ) : (
                <></>
              )}

              <FormWrapper>
                <Form
                  onSubmit={() => {}}
                  // tslint:disable-next-line:jsx-no-lambda
                  render={({ handleSubmit, form }) => {
                    formRef.current = form; // Set the form API to ref
                    return (
                      <form onSubmit={handleSubmit}>
                        <Field name="name" validate={required}>
                          {({ input, meta }) => (
                            <InputRow
                              className={
                                meta.error && meta.touched ? 'error' : ''
                              }
                            >
                              <div> {__('Vaše meno')}:</div>
                              <FormInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputRow>
                          )}
                        </Field>
                        <Field name="email" validate={required}>
                          {({ input, meta }) => (
                            <InputRow
                              className={
                                meta.error && meta.touched ? 'error' : ''
                              }
                            >
                              <div> {__('Váš email')}:</div>
                              <FormInput
                                {...input}
                                error={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </InputRow>
                          )}
                        </Field>
                        <Field
                          name="marketting_accept"
                          type="checkbox"
                          validate={required}
                        >
                          {({ input, meta }) => (
                            <div>
                              <CheckboxStyled
                                {...input}
                                value="1"
                                id="marketting_accept"
                              >
                                <span>
                                  {__(
                                    'Súhlasím so zasielaním obchodných oznámení a ',
                                  )}
                                  <a href="/gdpr" target="_blank">
                                    {__('spracovaním osobných údajov')}
                                  </a>
                                </span>
                              </CheckboxStyled>
                              {meta.error && meta.touched && (
                                <ErrorPopup>{meta.error}</ErrorPopup>
                              )}
                            </div>
                          )}
                        </Field>
                      </form>
                    );
                  }}
                />
              </FormWrapper>
            </div>
          }
          buttonText={__('Odoslať a získať zľavu')}
          onButtonClick={() => onSendFormClick()}
          onCloseButtonClick={() => {
            onFormModalClose();
          }}
        />
      ) : (
        <></>
      )}

      {thankYouModalVisible ? (
        <ConfirmModal
          title={newsletterCouponThankYouHeaderText}
          titleFontWeight="bold"
          bodyText={
            <div
              dangerouslySetInnerHTML={{
                __html: newsletterCouponThankYouBodyText
                  ? newsletterCouponThankYouBodyText
                  : '',
              }}
            />
          }
          buttonText={__('OK')}
          onButtonClick={() => onThankYouModalClose()}
          onCloseButtonClick={() => {
            onThankYouModalClose();
          }}
        />
      ) : (
        <></>
      )}
    </>
  );
};

const WidgetWrapper = styled(Container)`
  position: relative;
`;

const WidgetBox = styled.div`
  background: #ffe500;
  padding: 10px 50px 10px 20px;
  border-radius: 8px;
  display: flex;
  position: fixed;
  bottom: 15px;
  z-index: 9999;
  left: 15px;
  right: 15px;

  ${({ theme }) => theme.media(320)} {
    margin-left: 15px;
    left: initial;
    right: initial;
  }
`;

const Button = styled.button`
  ${ButtonStyles};
  ${ButtonStyledStyles};
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  height: 26px;
  font-weight: 700;
  width: fit-content;
  padding-left: 8px;
  padding-right: 8px;
  ${({ theme }) => theme.media('sm')} {
    font-size: 14px;
  }
`;

const WidgetText = styled.div`
  font-weight: bold;
  font-size: 11px;

  ${({ theme }) => theme.media(250)} {
    font-size: 12px;
  }

  ${({ theme }) => theme.media(265)} {
    font-size: 13px;
  }

  ${({ theme }) => theme.media(275)} {
    font-size: 14px;
  }

  ${({ theme }) => theme.media(290)} {
    font-size: 15px;
  }

  ${({ theme }) => theme.media(300)} {
    font-size: 16px;
  }

  ${({ theme }) => theme.media(320)} {
    font-size: 17px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CancelButton = styled.img`
  position: absolute;
  top: 12px;
  right: 10px;
  width: 22px;
  height: auto;
  cursor: pointer;

  background: #8080804f;
  padding: 4px;
  border-radius: 10px 10px 10px 10px;
`;

const InputRow = styled.div`
  margin-bottom: 15px;
`;

const FormInput = styled(InputStyled)`
  width: 100%;
  ${(props: any) => {
    return props.error ? 'border:2px solid red;' : '';
  }}
`;

const FormWrapper = styled.div`
  margin-top: ${rem(20)};
`;

const ErrorMessage = styled(Alert)`
  margin-top: ${rem(10)};
`;

const StarsImage = styled.img`
  position: absolute;
  left: 5p;
  right: 30px;
  bottom: 0px;
  z-index: -1;
`;

export default UpsellNewsletter;
